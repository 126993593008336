<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-button
        class="btn btn-gradient-primary mb-1 col-md-2 ml-1 custom-button"
        @click="$router.push({ name: 'admin-paymenttochina'})"
      >
        {{ $t('returnToList') }}
      </b-button>
      <b-button
        class="btn btn-gradient-primary mb-1 ml-1 col-md-2 custom-button"
        @click="Submit()"
      >
        {{ $t('saveData') }}
      </b-button>
      <b-button
        variant="danger"
        class="mb-1 ml-1 col-md-2 custom-button"
        @click="checkcancel()"
      >
        {{ $t('deleteItem') }}
      </b-button>
      <b-button
        variant="secondary"
        class="mb-1 ml-1 col-md-2 custom-button"
        @click="printpdf()"
      >
        {{ $t('print') }}
      </b-button>
      <b-button
        variant="info"
        class="mb-1 ml-1 col-md-2 custom-button"
        :disabled="Isdisabled"
        @click="RefundsShow()"
      >
        {{ $t('Refunds') }}
      </b-button>
      <b-button
        variant="danger"
        class="mb-1 ml-1 col-md-2 custom-button"
        :disabled="Isdisabled"
        @click="paynow()"
      >
        {{ $t('makePayment') }}
      </b-button>
      <b-row class="match-height">
        <b-col md="6">

          <div class="card">
            <div class="header-cardx p-2">
              <h3 class="mb-0">
                {{ $t('shippingAddress') }}
              </h3>
            </div>

            <div class="p-2">
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label-for="h-product-name"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('orderNumber') }} :
                    </template>
                    <b-form-input
                      id="h-product-name"
                      v-model="items.itemnumber_text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-status"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('status') }} :
                    </template>
                    <v-select
                      id="h-status"
                      v-model="items.status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="text"
                      :options="rolelist"
                      :reduce="ele => ele.value"
                      class="w-100 mr-1"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-customerCode"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('customerCode') }} :
                    </template>
                    <v-select
                      id="h-customerCode"
                      v-model="customerCode"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="username"
                      :options="Username"
                      class="w-100 mr-1"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-addr"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('address') }} :
                    </template>
                    <b-form-textarea
                      id="h-addr"
                      v-model="items.address"
                      type="text"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-phone-number"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('mobileNumber') }} :
                    </template>
                    <b-form-input
                      id="h-phone-number"
                      v-model="items.tolphone"
                      type="number"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="example-received"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('receive') }} :
                    </template>
                    <b-form-datepicker
                      id="example-received"
                      v-model="items.dateintime"
                      locale="th"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="example-export"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('export') }} :
                    </template>
                    <b-form-datepicker
                      id="example-export"
                      v-model="items.dateouttime"
                      locale="th"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="example-arrived-at"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('destinationReached') }} :
                    </template>
                    <b-form-datepicker
                      id="example-arrived-at"
                      v-model="items.datedestination"
                      locale="th"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-parcel1"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('exportTrackingNumber') }} :
                    </template>
                    <b-form-input
                      id="h-parcel1"
                      v-model="items.postnumber"
                      type="text"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-file1"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('shippingFile') }} :
                    </template>
                    <b-form-file
                      id="h-file1"
                      v-model="file_cus"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                      @change="fileImage"
                    />
                    <div class="d-flex justify-content-left p-1">
                      <span
                        v-if="imageOr"
                        class="preview-image-containerv2 mr-2 d-flex justify-content-center"
                      >
                        <img
                          :src="GetImg('Paymenttochina',imageOr)"
                          alt="Preview"
                          class=""
                          @click="showPicture(imageOr)"
                        >
                        <div class="preview-image-overlays p-0">
                          <i
                            class="fas fa-times-circle cursor-pointer"
                            @click="deleteImageorder()"
                          />
                        </div>

                        <vue-image-lightbox-carousel
                          ref="lightbox"
                          :show="showLightbox"
                          :images="images"
                          :show-caption="false"
                          @close="closeBox()"
                        />
                      </span>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>

        <b-col md="6">
          <div
            class="card"
          >
            <div class="header-cardx p-2">
              <h3 class="mb-0">
                {{ $t('relatedAmountList') }}
              </h3>
            </div>

            <div class="pl-2 pr-2">
              <b-table
                responsive="sm"
                :fields="fieldsRelevant"
                :items="itemsRelevant"
                class="mb-2"
                show-empty
              >

                <template #empty>
                  <div class="text-center">
                    <img
                      src="@/assets/images/anan-img/empty/empty.png"
                      alt="empty"
                      width="180px"
                    >
                    <p>No Data</p>
                  </div>
                </template>
                <template #cell(amount)="data">
                  <span :style="getAmountStyle(data.item.type, data.item.type_amount)">
                    {{ getAmountPrefix(data.item.type, data.item.type_amount) }} {{ Commas(data.item.amount.toFixed(2)) }} ฿
                  </span>

                </template>
              </b-table>
            </div>

          </div>
        </b-col>

        <b-col md="12">
          <div class="card">
            <div class="header-cardx p-2">
              <div class="d-flex justify-content-between align-items-center">
                <h3 class="mb-0">
                  {{ $t('parcelCodeList') }}
                </h3>

                <a
                  href="javascript:void(0)"
                  @click="showAdd()"
                >
                  <i class="fal fa-plus" /> {{ $t('addNewItem') }}
                </a>
              </div>
            </div>

            <div class="pl-2 pr-2">
              <b-table
                responsive="sm"
                :fields="fieldsParcel"
                :items="items.detailspost"
                class="mb-2"
                show-empty
              >

                <template #empty>
                  <div class="text-center">
                    <img
                      src="@/assets/images/anan-img/empty/srry.svg"
                      alt="empty"
                      width="180px"
                    >
                    <p>No Data</p>
                  </div>
                </template>
                <template #cell(id)="data">
                  {{ data.index+1 }}
                </template>
                <template #cell(parcelCode)="data">
                  {{ data.item.postnumber ? data.item.postnumber: 0 }}
                </template>
                <template #cell(weight)="data">
                  {{ data.item.weight ? Commas(data.item.weight): 0 }}
                </template>
                <template #cell(width)="data">
                  {{ data.item.width ? Commas(data.item.width): 0 }}
                </template>
                <template #cell(height)="data">
                  {{ data.item.height ? Commas(data.item.height): 0 }}
                </template>
                <template #cell(long)="data">
                  {{ data.item.long ? Commas(data.item.long): 0 }}
                </template>
                <template #cell(packagePrice)="data">
                  {{ data.item.packproduct ? Commas(data.item.packproduct): 0 }}
                </template>
                <template #cell(picture)="data">
                  <img
                    v-if="data.item.fileproduct"
                    :src="GetImg('Paymenttochina', data.item.fileproduct)"
                    alt="Preview"
                    class="preview-image-containerv2 mr-2 ml-3 mt-1 d-flex justify-content-center"
                    @click="showPicture(data.item.fileproduct)"
                  >
                  <span v-else>-</span>
                </template>
                <template #cell(freight)="data">
                  {{ data.item.shipping_fee ? Commas(data.item.shipping_fee): 0 }}
                </template>
                <template #cell(actions)="data">
                  <feather-icon
                    icon="Edit3Icon"
                    class="mr-1 text-primary cursor-pointer"
                    @click="showAdd(data.item)"
                  />

                  <feather-icon
                    icon="Trash2Icon"
                    class="text-danger cursor-pointer"
                    @click="DeleteType(data.item)"
                  />
                </template>
              </b-table>
            </div>
          </div>
        </b-col>
      </b-row>
    </validation-observer>
    <!-- เพิ่มที่อยู่ใหม่ -->
    <b-modal
      id="add-addr"
      :title="this.$t('parcelCode')"
      :ok-title="this.$t('save')"
      :cancel-title="this.$t('cancel')"
      @ok.prevent="addParcel()"
    >

      <b-form-group
        label-for="h-number"
        :label="this.$t('sequence')"
        class="inline"
      >

        <b-form-input
          id="h-number"
          v-model="numberitem"
          disabled
        />

      </b-form-group>

      <b-form-group
        label-for="h-parcel-code"
        :label="this.$t('parcelCode')"
      >
        <b-form-input
          id="h-parcel-code"
          v-model="parcelCode"
        />
      </b-form-group>

      <div class="row">
        <div class="col-6">
          <b-form-group
            label-for="h-weight"
            :label="this.$t('weight')"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="h-weight"
                v-model="weight"
                type="number"
                @input="ShippingCheck(weight)"
              />
              <b-input-group-append is-text>
                {{ $t('kilo') }}
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>

        <div class="col-6">
          <b-form-group
            label-for="h-height"
            :label="this.$t('heightCm')"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="h-height"
                v-model="height"
                type="number"
              />
              <b-input-group-append is-text>
                {{ $t('cm') }}
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>

        <div class="col-6">
          <b-form-group
            label-for="h-width"
            :label="this.$t('widthCm')"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="h-width"
                v-model="width"
                type="number"
              />
              <b-input-group-append is-text>
                {{ $t('cm') }}
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>

        <div class="col-6">
          <b-form-group
            label-for="h-long"
            :label="this.$t('lengthCm')"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="h-long"
                v-model="long"
                type="number"
              />
              <b-input-group-append is-text>
                {{ $t('cm') }}
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </div>

      <b-form-group
        label-for="h-package-price"
        :label="this.$t('PackCost')"
      >
        <b-input-group class="input-group-merge">
          <b-form-input
            id="h-package-price"
            v-model="packagePrice"
            type="number"
          />
          <b-input-group-append is-text>
            ฿
          </b-input-group-append>
        </b-input-group>
      </b-form-group>

      <b-form-group
        label-for="h-district-name"
        :label="this.$t('productImageFile')"
      >
        <b-form-file
          id="h-district-name"
          v-model="district"
          placeholder="Choose a file or drop it here..."
          @change="fileImagedistrict"
        />
        <!-- <div class="d-flex justify-content-content p-4"> -->
        <span
          v-if="imagepost"
          class="preview-image-containerv2 mr-2 ml-3 mt-1 d-flex justify-content-center"
        >
          <img
            :src="GetImg('Paymenttochina', imagepost)"
            alt="Preview"
            class=""
            @click="showPicture(imagepost)"
          >
          <div
            class="preview-image-overlays"
          >
            <i
              class="fas fa-times-circle cursor-pointer"
              @click="deleteImage()"
            />
          </div>
        </span>
        <!-- </div> -->
      </b-form-group>

    </b-modal>

    <!-- ชำระเงิน -->
    <b-modal
      id="add-payment"
      :title="$t('details')"
      size="md"
      :ok-title="$t('key-13')"
      :cancel-title="$t('cancel')"
      :ok-disabled="Isdisabled"
      @ok.prevent="PaymentNow()"
    >

      <div class="w-100">
        <div class="d-flex align-items-center pt-1">
          <div class="w-25 text-right">
            <span class="font-weight-bolder pr-1">
              {{ $t('customerCode') }} :
            </span>
          </div>
          <div class="w-75">
            {{ customerCode ? customerCode.username: '' }}
          </div>
        </div>
      </div>
      <div class="w-100">
        <div class="d-flex align-items-center pt-1">
          <div class="w-25 text-right">
            <span class="font-weight-bolder pr-1">
              {{ $t('key-46') }} :
            </span>
          </div>
          <div class="w-75">
            {{ balanceuser ? balanceuser.balance: 0 }} {{ this.$t('baht') }}
          </div>
        </div>
      </div>

      <div class="w-100">
        <div class="d-flex align-items-center pt-1">
          <div class="w-25 text-right">
            <span class="font-weight-bolder pr-1">
              {{ $t('key-100') }} :
            </span>
          </div>
          <div class="w-75">
            {{ Commas(items.detailspost.reduce((total, item) => total + Number(item.weight),0)) }} {{ this.$t('key-317') }}
          </div>
        </div>
      </div>
      <div class="w-100">
        <div class="d-flex align-items-center pt-1">
          <div class="w-25 text-right">
            <span class="font-weight-bolder pr-1">
              {{ $t('shippingFee') }} :
            </span>
          </div>
          <div class="w-75">
            {{ Commas(items.detailspost.reduce((total, item) => total + Number(item.shipping_fee),0)) }} {{ this.$t('baht') }}
          </div>
        </div>
      </div>
      <div class="w-100">
        <div class="d-flex align-items-center pt-1">
          <div class="w-25 text-right">
            <span class="font-weight-bolder pr-1">
              {{ $t('PackCost') }} :
            </span>
          </div>
          <div class="w-75">
            {{ Commas(items.detailspost.reduce((total, item) => total + Number(item.packproduct),0)) }} {{ this.$t('baht') }}
          </div>
        </div>
      </div>
      <div class="w-100">
        <div class="d-flex align-items-center pt-1">
          <div class="w-25 text-right">
            <span class="font-weight-bolder pr-1">
              {{ $t('totalPaid') }} :
            </span>
          </div>
          <div class="w-75">
            <b-input-group
              class="input-group-merge w-75"
              append="฿"
            >
              <b-form-input
                id="h-MorePayment-amount"
                v-model="MorePayment"
                type="number"
              />
            </b-input-group>
          </div>
        </div>
      </div>

    </b-modal>
    <b-modal
      id="add-Refunds"
      :title="$t('details')"
      size="md"
      :ok-title="$t('key-13')"
      :cancel-title="$t('cancel')"
      :ok-disabled="Isdisabled"
      @ok.prevent="Refundspaynow()"
    >

      <div class="w-100">
        <div class="d-flex align-items-center pt-1">
          <div class="w-25 text-right">
            <span class="font-weight-bolder pr-1">
              {{ $t('customerCode') }} :
            </span>
          </div>
          <div class="w-75">
            {{  customerCode ? customerCode.username : '' }}
          </div>
        </div>
      </div>
      <div class="w-100">
        <div class="d-flex align-items-center pt-1">
          <div class="w-25 text-right">
            <span class="font-weight-bolder pr-1">
              {{ $t('key-46') }} :
            </span>
          </div>
          <div class="w-75">
            {{ balanceuser ? balanceuser.balance: 0 }} {{ this.$t('baht') }}
          </div>
        </div>
      </div>

      <div class="w-100">
        <div class="d-flex align-items-center pt-1">
          <div class="w-25 text-right">
            <span class="font-weight-bolder pr-1">
              {{ $t('Refunds') }} :
            </span>
          </div>
          <div class="w-75">
            <b-input-group
              class="input-group-merge w-75"
              append="฿"
            >
              <b-form-input
                id="h-MorePayment-amount"
                v-model="MorePayment"
                type="number"
              />
            </b-input-group>
          </div>
        </div>
      </div>
      <div class="w-100">
        <div class="d-flex align-items-center pt-1">
          <div class="w-25 text-right">
            <span class="font-weight-bolder pr-1">
              {{ $t('remarks') }} :
            </span>
          </div>
          <div class="w-75">
            <b-form-textarea
              id="remark"
              v-model="remark"
              type="text"
            />
          </div>
        </div>
      </div>

    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BModal,
  BInputGroup,
  BInputGroupAppend,
  BTable,
  BFormFile,
  BFormDatepicker,
  BButton,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import moment from 'moment-timezone'

export default {
  components: {
    BRow,
    BCol,
    // ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    vSelect,
    BModal,
    BInputGroup,
    BInputGroupAppend,
    BTable,
    BFormFile,
    BFormDatepicker,
    BButton,
    VueImageLightboxCarousel,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      images: [],
      showLightbox: false,
      required,
      email,
      file: null,
      fullname: null,
      tel: null,
      tel2: null,
      different: null,
      invited_by: null,
      role: null,
      // username: [],
      password: null,
      sub_role: [],
      Address: [],
      itemsCar: [],
      itemsShip: [],
      subrole: [],
      status: { text: 'รอการส่ง', value: 3 },

      // Add Address
      name: null,
      phone: null,
      address: null,
      province: null,
      district: null,
      subdistrict: null,
      postalcode: null,
      file_cus: null,
      selectedFiles: [],
      uploadedImages: [],
      imageOr: null,
      module_list: [],
      module_use: '',
      group_user_list: [],
      group_use: '',
      group_user_type: 1,
      group_userloading: false,
      category: '',
      ID: this.$route.params.id,
      items: [],
      Username: [],
      customerCode: null,
      imagepost: null,
      numberitem: '',
      parcelCode: null,
      weight: 0,
      height: 0,
      width: 0,
      long: 0,
      packagePrice: 0,
      id: null,
      shipping_fee: 0,
      Isdisabled: false,
      balanceuser: null,
      MorePayment: 0,
      itemsRelevant: [],
      remark: null,
    }
  },
  computed: {
    fieldsParcel() {
      return [
        {
          key: 'actions', label: this.$t('manage'), thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'id', label: this.$t('sequence'), thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'parcelCode', label: this.$t('parcelCode'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'weight', label: this.$t('weight'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'width', label: this.$t('width'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'height', label: this.$t('height'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'long', label: this.$t('length'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'packagePrice', label: this.$t('PackCost'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'picture', label: this.$t('productImageFile'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'freight', label: this.$t('shippingFee'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
    fieldsRelevant() {
      return [
        {
          key: 'created_at', label: this.$t('transactionDate'), thStyle: { width: '20%' }, formatter: value => moment(value).format('DD/MM/YYYY HH:mm:ss'),
        },
        { key: 'detail', label: this.$t('details'), thStyle: { width: '60%' } },
        { key: 'amount', label: this.$t('quantity'), thStyle: { width: '20%' } },
      ]
    },

    rolelist() {
      return [
        { text: this.$t('cancel'), value: 0 },
        { text: this.$t('unpaid'), value: 1 },
        { text: this.$t('paidAmount'), value: 2 },
        { text: this.$t('WaitingVerification'), value: 3 },
        { text: this.$t('awaitingDelivery'), value: 4 },
        { text: this.$t('delivered'), value: 5 },
      ]
    },

    passwordToggleIcon() {
      return this.passwordFieldType === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
  },
  async mounted() {
    await this.getUsername()
    this.getData()
    this.Gettransactions()
  },
  methods: {
    Refundspaynow() {
      const obj = {
        ID: this.ID,
        amount: Number(this.MorePayment),
        uid: this.customerCode.id,
        username: this.customerCode.username,
        user_id: this.customerCode.itemnumber_text,
        remark: this.remark,
      }
      this.$http.post('Paymenttochina/PaymentChina/Refunds', obj)
        .then(response => {
          if (response.data.status) {
            this.Isdisabled = false
            this.Success(response.data.data)
            this.Gettransactions()
            this.getData()
            this.$bvModal.hide('add-Refunds')
          }
        })
        .catch(err => {
          console.log(err)
          this.SwalError(err.response.data.data)
        })
    },
    RefundsShow() {
      this.MorePayment = 0
      this.remark = null
      if (!this.customerCode) {
        this.SwalError('ไม่มีข้อมูลลูกค้า')
      } else {
        this.$bvModal.show('add-Refunds')
      }
    },
    async Gettransactions() {
      try {
        const params = {
          ref_id: this.ID,
        }
        const { data: res } = await this.$http.get('/transactions/show/ref', { params })
        if (res.success) {
          this.itemsRelevant = res.data
        }
      } catch (err) {
        console.log(err)
      }
    },
    GetImg(pathfile, filename) {
      const matches = filename.match(/^data:image\/([A-Za-z-+\/]+);base64,(.+)$/)
      if (!matches || matches.length !== 3) {
        return `${this.$http.defaults.baseURL}/viewimg?pathfile=${pathfile}/${filename}`
      }
      return filename
    },
    async getData() {
      try {
        const params = {
          id: this.ID,
        }
        const { data: res } = await this.$http.get('/Paymenttochina/getDataOne', { params })
        this.items = res
        if (this.items.uid) {
          this.customerCode = this.Username.find(ele => ele.id === this.items.uid)
          this.Getbalance(this.items.uid)
        }
        this.imageOr = this.items.imageOr
        this.numberitem = `${this.items.itemnumber_text}.${this.items.detailspost.length + 1}`
      } catch (err) {
        console.log(err)
        this.SwalError(err)
      }
    },
    getAmountStyle(amount, typeAmount) {
      if (amount === 21) {
          if (typeAmount) {
            return { color: 'green' }
          } else {
            return { color: 'red' }
          }
        } else {
            if ([1, 8, 11, 12, 17, 16].includes(amount)) {
              return { color: 'green' }
            }
            if ([2, 3, 4, 5, 6, 7, 13, 15, 18, 10, 9, 20].includes(amount)) {
              return { color: 'red' }
            }
        }
    },
    getAmountPrefix(value, typeAmount) {
      if (value === 21) {
          if (typeAmount) {
            return '+'
          } else {
            return '-'
          }
        } else {
            if ([1, 8, 11, 12, 17, 16].includes(value)) {
              return '+'
            }
            if ([2, 3, 4, 5, 6, 7, 13, 15, 18, 10, 9, 20].includes(value)) {
                return '-'
            }
        }
    },
    Getbalance(ID) {
      const params = {
        uid: ID,
      }
      this.$http.get('/Balance/getbalance', { params })
        .then(response => {
          if (response.data) {
            this.balanceuser = response.data
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    paynow() {
      this.MorePayment = 0
      if (!this.customerCode) {
        this.SwalError('ไม่มีข้อมูลลูกค้า')
      } if (!this.items.detailspost.length) {
        this.SwalError('ไม่มีข้อมูลรายการรหัสพัสดุ')
      } else {
        this.MorePayment = this.items.detailspost.reduce((total, item) => total + Number(item.packproduct) + Number(item.shipping_fee), 0)
        this.$bvModal.show('add-payment')
      }
    },
    async PaymentNow() {
      this.Isdisabled = true
      if (!this.MorePayment || this.MorePayment === '0') {
        this.SwalError('กรุณากรอกจำนวนยอดเงิน')
        this.Isdisabled = false
        return
      } if (Number(this.balanceuser.balance) < Number(this.MorePayment)) {
        this.SwalError('ยอดเงินลูกค้าไม่เพียงพอ')
        this.Isdisabled = false
        return
      }
      const obj = {
        ID: this.ID,
        amount: Number(this.MorePayment),
        uid: this.customerCode.id,
        username: this.customerCode.username,
        user_id: this.customerCode.itemnumber_text,
      }
      this.$http.post('Paymenttochina/PaymentChina/now', obj)
        .then(response => {
          if (response.data.status) {
            this.Isdisabled = false
            this.Success(response.data.data)
            this.Gettransactions()
            this.getData()
            this.$bvModal.hide('add-payment')
          }
        })
        .catch(err => {
          console.log(err)
          this.SwalError(err.response.data.data)
        })
    },
    async getUsername() {
      try {
        const { data: res } = await this.$http.get('/users/customer/active')
        this.Username = res
      } catch (err) {
        console.log(err)
      }
    },
    checkcancel() {
      this.$bvModal.msgBoxConfirm(this.$t('DeleteInformation'), {
        title: this.$t('confirm'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      }).then(result => {
        if (result) {
          const obj = {
            id: this.ID,
          }
          this.$http.post('/Paymenttochina/DeleteData', obj)
            .then(() => {
              this.Success('ลบข้อมูลเรียบร้อย')
              this.$router.push({ name: 'admin-paymenttochina' })
            })
            .catch(err => {
              console.log(err)
              this.SwalError(err)
            })
        }
      })
    },
    addParcel() {
      this.$bvModal.msgBoxConfirm(this.$t('ConfirmInformation'), {
        title: this.$t('confirm'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'warning',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      }).then(result => {
        if (result) {
          const obj = {
            id: this.id,
            ID: this.ID,
            parcelCode: this.parcelCode,
            weight: this.weight,
            imagepost: this.imagepost,
            height: this.height,
            width: this.width,
            long: this.long,
            packagePrice: this.packagePrice,
            shipping_fee: this.shipping_fee,
          }
          this.$http.post('/Paymenttochina/Update/Postnumber', obj)
            .then(() => {
              this.getData()
              this.Success(this.$t('recordSuccess'))
              this.$bvModal.hide('add-addr')
            })
            .catch(err => {
              console.log(err)
              this.SwalError(err)
            })
        }
      })
    },
    DeleteType(data) {
      this.$bvModal.msgBoxConfirm(this.$t('DeleteInformation'), {
        title: this.$t('confirm'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      }).then(result => {
        if (result) {
          const obj = {
            // eslint-disable-next-line no-underscore-dangle
            id: data._id,
            ID: this.ID,
          }
          this.$http.post('/Paymenttochina/Delete/Postnumber', obj)
            .then(() => {
              this.getData()
              this.Success('ลบข้อมูลเรียบร้อย')
            })
            .catch(err => {
              console.log(err)
              this.SwalError(err)
            })
        }
      })
    },
    printpdf() {
      try {
        window.open(`https://api.p2cargo.com/api/print/Paymenttochina/${this.ID}`)
      } catch (err) {
        this.SwalError(err)
        console.log(err)
      }
    },
    Submit() {
      const obj = {
        id: this.ID,
        status: this.items.status,
        uid: null,
        username: null,
        address: this.items.address,
        tolphone: this.items.tolphone,
        dateintime: this.items.dateintime,
        dateouttime: this.items.dateouttime,
        datedestination: this.items.datedestination,
        postnumber: this.items.postnumber,
        imageOr: this.imageOr,
      }
      if (this.customerCode) {
        obj.uid = this.customerCode.id
        obj.username = this.customerCode.username
      }
      this.$http.post('/Paymenttochina/Update', obj)
        .then(response => {
          if (response.data.status) {
            this.getData()
            this.Success(this.$t('recordSuccess'))
          }
        })
        .catch(err => {
          console.log(err)
          this.SwalError(err.response.data.status)
        })
    },
    fileImage(event) {
      if (event) {
        this.file_cus = event.target.files
        // this.fileimg_th = null
        const reader = new FileReader()
        const filepath = this.file_cus[0]
        if (!filepath) {
          this.imageOr = null
        } else {
          reader.onload = e => {
            // console.log(e.target.result)
            // console.log(e.target.result)
            this.imageOr = e.target.result
            // console.log(this.filecusimg)
            // e.target.result
            // console.log(this.namemap)
          }
          reader.readAsDataURL(filepath)
        }
      }
    },
    fileImagedistrict(event) {
      if (event) {
        this.district = event.target.files
        // this.fileimg_th = null
        const reader = new FileReader()
        const filepath = this.district[0]
        if (!filepath) {
          this.imagepost = null
        } else {
          reader.onload = e => {
            // console.log(e.target.result)
            // console.log(e.target.result)
            this.imagepost = e.target.result
            // console.log(this.filecusimg)
            // e.target.result
            // console.log(this.namemap)
          }
          reader.readAsDataURL(filepath)
        }
      }
    },
    ShippingCheck(data) {
      const items = Math.ceil(data)
      this.shipping_fee = items * 400
    },
    showPicture(picture) {
      this.showLightbox = true
      const img = { path: this.GetImg('Paymenttochina', picture) }
      this.images.push(img)
      // this.$swal({
      //   imageUrl: picture,
      //   imageWidth: 400,
      //   imageHeight: 400,
      //   imageAlt: 'Custom image',
      //   confirmButtonText: 'ปิด',
      // })
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },
    deleteImageorder() {
      this.$bvModal.msgBoxConfirm(this.$t('DeleteImgInformation'), {
        title: this.$t('confirm'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      }).then(result => {
        if (result) {
          this.imageOr = null
          this.file_cus = null
        }
      })
    },
    deleteImage() {
      this.$bvModal.msgBoxConfirm(this.$t('DeleteImgInformation'), {
        title: this.$t('confirm'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      }).then(result => {
        if (result) {
          this.imagepost = null
          this.district = null
        }
      })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    showAdd(data) {
      if (data) {
        // eslint-disable-next-line no-underscore-dangle
        this.id = data._id
        this.numberitem = `${this.items.itemnumber_text}.${this.items.detailspost.length}`
        this.imagepost = data.fileproduct
        this.parcelCode = data.postnumber
        this.weight = data.weight
        this.height = data.height
        this.width = data.width
        this.long = data.long
        this.packagePrice = data.packproduct
        this.shipping_fee = data.shipping_fee
      } else {
        this.id = null
        this.numberitem = `${this.items.itemnumber_text}.${this.items.detailspost.length + 1}`
        this.imagepost = null
        this.parcelCode = null
        this.weight = 0
        this.height = 0
        this.width = 0
        this.long = 0
        this.packagePrice = 0
        this.shipping_fee = 0
      }
      this.$bvModal.show('add-addr')
    },
    showModal() {
      this.name = null
      this.phone = null
      this.address = null
      this.province = null
      this.district = null
      this.subdistrict = null
      this.postalcode = null
      this.$bvModal.show('modal-1')
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@media (min-width: 1024px) {
  .custom-button {
    width: 250px; /* กำหนดความกว้างสำหรับหน้าจอใหญ่ */
  }
}
</style>
